import { useTranslation } from "react-i18next";
import { header } from "../assets";
import { useCallback } from "react";

import Logo from "../components/common/Logo";
import Order from "../components/Order";

const Header = () => {
  const { t } = useTranslation();
  const text = useCallback((key) => t(`header.${key}`), [t]);

  return (
    <header>
      <div
        className="d-flex justify-content-between align-items-center margin-auto m-auto flex-wrap"
        style={{
          minWidth: "90%",
        }}
      >
        <div className="my-2 px-2">
          <Logo />
          <h2
            className="my-4 font-weight-bold"
            style={{
              width: "12em",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              lineHeight: 1.5,
            }}
          >
            {text("heading")}
          </h2>
          <p className="text-lg-left" style={{ maxWidth: "30em" }}>
            {text("paragraph")}
          </p>
          <Order />
        </div>
        <div
          style={{
            overflow: "hidden",
            height: "25em",
          }}
        >
          <img
            style={{
              maxWidth: "28em",
              marginTop: "2em",
            }}
            src={header}
            alt="Halalo Header"
            loading="lazy"
          />
        </div>

      </div>

    </header>
  );
};

export default Header;

import React, { useCallback } from "react";

import { SubHeaderProps } from "../../@types";

import { useTranslation } from "react-i18next";

import Logo from "./Logo";

const SubHeader: React.FC<SubHeaderProps> = ({ title }) => {
  const { t } = useTranslation();
  const text = useCallback((key) => t(`content.${key}`), [t]);

  return (
    <header className="d-flex flex-column height-25em sub-header">
      <div className="width-90">
        <Logo />
        <h1 className="sub-header-title">{text(title)}</h1>
      </div>
    </header>
  );
};

export default SubHeader;

import React from "react";

// import { getMethod, cancelConnection } from "../utills/API";

import SubHeader from "../components/common/Sub-Header";
import BackNav from "../components/common/back-nav";
// import Loading from "../components/common/Loading";
import { AGBData, PrivacyData, impressumData } from './StaticData';


const Screen = ({ title, apiQuery }: { title: string; apiQuery: string }) => {
  // const [content, setContent] = useState("");
  // const [loading, setLoading] = useState(true);

  // const getContent = useCallback(async () => {
  //   try {
  //     const res = await getMethod.getContent(apiQuery);
  //     setContent(res?.[0][apiQuery]);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error While Getting Data");
  //   }
  //   return cancelConnection;
  // }, [apiQuery]);

  // useEffect(() => {
  //   getContent();
  // }, [getContent]);

  function createMarkup() {
    switch (title) {
      case 'Impressum':
        return { __html: impressumData };
      case 'AGB':
        return { __html: AGBData };
      case 'Datenschutz':
        return { __html: PrivacyData };
      default:
        break;
    }
  }

  return (
    <>
      <SubHeader title={title} />
      <div className="sub-container">
        <BackNav />
        {/* {loading ? (
          <Loading />
        ) : content ? ( */}
        <div
          dangerouslySetInnerHTML={createMarkup()}
        />
        {/* ) : (
          ""
        )} */}
      </div>
    </>
  );
};
export default Screen;

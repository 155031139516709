export const de = {
  translation: {
    header: {
      heading: "Deine Lebensmittel geliefert in 45 Minuten.",
      paragraph:
        "Egal, was Du gerade brauchst: Wir liefern Dir Deinen Einkauf inklusive frischem Obst und Gemüse in höchster Qualität direkt nach Hause. Innerhalb von Minuten und zu Supermarkt-Preisen.",
    },
    content: {
      Home: {
        paragraph: `Wir verwenden Cookies und vergleichbare Technologien, die entweder für den Websitebetrieb notwendig sind oder nur mit Ihrer Einwilligung für zusätzliche Funktionen, Tracking und personalisiertes Marketing verwendet werden. 
                Ihre Einwilligung umfasst auch die Übermittlung Ihrer Daten in Drittländer, bei denen das Risiko eines Zugriffs durch Behörden besteht. 
                Sie können Ihre Einwilligung zur Datenverarbeitung und -übermittlung jederzeit widerrufen. 
                Weitere Details hierzu finden Sie in unserer`,
        link: `Datenschutzerklärung`,
        ideasButton: `Einstellungen`,
        AcceptButton: `Alle akzeptieren`,
        orderNow: `Jetzt bestellen`,
        orderDescription: `Deine Lebensmittel in ca. 45 Minuten an Deiner Haustür. Jetzt die Halalo-App downloaden!`,
      },
      Impressum: "Impressum",
      Datenschutz: "Datenschutz",
      AGB: "AGB",
      back: " < Zurück",
    },
  },
};

const Privacy = `
<h1>Halalo GmbH &amp; Co. KG Datenschutzerkl&auml;rung f&uuml;r die mobile App</h1>
<p>Dieser Dienst (nachfolgend &bdquo;App&ldquo;) wird von der Halalo GmbH &amp; Co. KG mit Sitz in der Langbargheide 4, 22547 Hamburg (nachfolgend &bdquo;wir&ldquo; oder &bdquo;uns&ldquo;) als Verantwortlicher im Sinne des jeweils geltenden Datenschutzrechts zur Verf&uuml;gung gestellt.</p>
<p>&nbsp;</p>
<h2>Was macht Halalo?</h2>
<p>Halalo ist ein Lieferdienst f&uuml;r Lebensmittel aus orientalischen M&auml;rkten in Ihrer Umgebung.</p>
<p>Informationen zur Verarbeitung Ihrer Daten</p>
<p>Bestimmte Informationen werden bereits automatisch verarbeitet, sobald Sie die App verwenden. Welche personenbezogenen Daten genau verarbeitet werden, haben wir im Folgenden f&uuml;r Sie aufgef&uuml;hrt:</p>
<h2>Informationen, die automatisch erhoben werden</h2>
<p>Im Rahmen Ihrer Nutzung der App erheben wir bestimmte Daten automatisch, die f&uuml;r die Nutzung der App erforderlich sind. Hierzu geh&ouml;ren: interne Ger&auml;te-ID, Version Ihres Betriebssystems, Zeitpunkt des Zugriffs.</p>
<p>Diese Daten werden automatisch an uns &uuml;bermittelt, aber nicht gespeichert,</p>
<ul>
    <li>um Ihnen den Dienst und die damit verbundenen Funktionen zur Verf&uuml;gung zu stellen;</li>
    <li>die Funktionen und Leistungsmerkmale der App zu verbessern und</li>
    <li>Missbrauch sowie Fehlfunktionen vorzubeugen und zu beseitigen.</li>
</ul>
<p>Diese Datenverarbeitung ist dadurch gerechtfertigt, dass (1) die Verarbeitung f&uuml;r die Erf&uuml;llung des Vertrags zwischen Ihnen als Betroffener und uns gem&auml;&szlig; Art. 6 Abs. 1 lit. b) DSGVO zur Nutzung der App erforderlich ist, oder (2) wir ein berechtigtes Interesse daran haben, die Funktionsf&auml;higkeit und den fehlerfreien Betrieb der App zu gew&auml;hrleisten und einen markt- und interessengerechten Dienst anbieten zu k&ouml;nnen, das hier Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f) DSGVO &uuml;berwiegt.</p>
<h2>Registrierung</h2>
<p>Wenn Sie einen Nutzeraccount erstellen oder sich anmelden, verwenden wir Ihre Zugangsdaten</p>
<ul>
    <li>Vor- und Nachname,</li>
    <li>Ihre Lieferadresse,</li>
    <li>Telefonnummer</li>
    <li>E-Mail-Adresse.</li>
</ul>
<p>Zudem bitten wir Sie ein Passwort f&uuml;r Ihr Konto zu erstellen, und werden diese</p>
<p>Passwortinformationen verschl&uuml;sselt speichern.</p>
<p>Diese Daten im Rahmen der Registrierung sind f&uuml;r den Abschluss des Nutzungsvertrages erforderlich. Wenn Sie diese Daten nicht angeben, k&ouml;nnen Sie keinen Nutzeraccount erstellen. Die Pflichtangaben verwenden wir, um Sie beim Login zu authentifizieren und Anfragen zur R&uuml;cksetzung Ihres Passwortes nachzugehen. Die von Ihnen im Rahmen der Registrierung oder einer Anmeldung eingegebenen Daten werden von uns verarbeitet und verwendet,</p>
<ul>
    <li>um Ihre Berechtigung zur Verwaltung des Nutzeraccounts zu verifizieren;</li>
    <li>die Nutzungsbedingungen der App sowie alle damit verbundenen Rechte und Pflichten durchzusetzen und</li>
    <li>mit Ihnen in Kontakt zu treten, um eventuelle Reklamationen abzukl&auml;ren.</li>
</ul>
<p>&nbsp;</p>
<h2>Nutzung der App</h2>
<p>Im Rahmen der App k&ouml;nnen Sie Ihre Profilinformationen eingeben, verwalten und bearbeiten. Zu diesen Informationen</p>
<p>geh&ouml;ren insbesondere Ihr Vor- und Nachname, &nbsp;Ihre Lieferadresse und Ihr Passwort.</p>
<p>Die App erfordert au&szlig;erdem die folgenden Berechtigungen:</p>
<ul>
    <li>Internetzugang: Dieser ist erforderlich, damit Sie auf die App zugreifen und diese nutzen k&ouml;nnen.</li>
    <li>Benutzerauthentifizierung: Dies ist erforderlich, um Ihre Identit&auml;t als Kontoinhaber/ Kontoinhabering zu verifizieren.</li>
    <li>Ortungsfunktion: Falls Sie der Standortermittlung Ihres Ger&auml;tes zugestimmt haben erheben wir Ihren aktuellen Standort &uuml;ber GPS, um Ihnen schnell Informationen zu den M&auml;rkten in Ihrer Umgebung geben zu k&ouml;nnen. Daten zu Ihrem Standort werden nur f&uuml;r die Bearbeitung Ihrer Anfrage genutzt. Die &Uuml;bertragung Ihrer Standortdaten erfolgt &uuml;ber eine verschl&uuml;sselte Verbindung. Ihre Standortdaten werden nach Beendigung Ihrer Anfrage verschl&uuml;sselt in Ihren Kontoinformationen gespeichert.</li>
</ul>
<p>Nutzungsdaten werden zur Erbringung des Dienstes verarbeitet und genutzt. Diese Datenverarbeitung ist zul&auml;ssig, da die Verarbeitung f&uuml;r die Erf&uuml;llung des Vertrages zwischen Dir als betroffene Person und uns gem&auml;&szlig;</p>
<p>Art. 6 Abs. 1 lit. b) DSGVO f&uuml;r die Nutzung der App erforderlich ist.</p>
<p>&nbsp;</p>
<h2>Bearbeitung von Bestellungen</h2>
<p>Wenn Sie unsere App nutzen, ben&ouml;tigen wir bestimmte Informationen, um Ihre Bestellung zu bearbeiten und die Produkte an Sie zu liefern. Zu den personenbezogenen Daten, die wir in diesem Zusammenhang verarbeiten,geh&ouml;ren:</p>
<ul>
    <li>Identit&auml;ts- und Kontaktdaten</li>
    <li>Ihre Kunden /Kundinen-ID</li>
    <li>Ihre Zahlungsarten</li>
    <li>Ihre Einkaufshistorie</li>
    <li>Favoriten</li>
    <li>Bestell- und Rechnungsinformationen</li>
</ul>
<p>&nbsp;sowie alle an uns gerichteten Anfragen oder Beschwerden.</p>
<p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zur Erf&uuml;llung des mit Ihnen geschlossenen Vertrags.</p>
<p>&nbsp;</p>
<h2>Kontaktaufnahme per E-Mail und Kontaktformular</h2>
<p>Wenn Sie uns per E-Mail oder &uuml;ber ein Kontaktformular kontaktieren, werden Ihre E-Mail-Adresse und Ihr Name und Ihre Telefonnummer von uns gespeichert, um Ihre Anfragen zu bearbeiten.</p>
<p>Die in diesem Zusammenhang verarbeiteten Daten l&ouml;schen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schr&auml;nken &ndash; im Falle von gesetzlichen Aufbewahrungspflichten &ndash; die Verarbeitung ein.</p>
<p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt aufgrund Deiner Einwilligung, welche Sie durch die Zusendung der E-Mail zum Ausdruck bringen.</p>
<p>&nbsp;</p>
<h2>Weitergabe und &Uuml;bertragung von Daten</h2>
<p>Eine Weitergabe Ihrer personenbezogenen Daten erfolgt nicht.</p>
<p>&nbsp;</p>
<h2>Zeitraum der Datenspeicherung</h2>
<p>Wir l&ouml;schen oder anonymisieren Ihre personenbezogenen Daten, sobald sie f&uuml;r die Zwecke der Erf&uuml;llung unserer Dienstleistungen oder um geltende gesetzliche, steuerliche oder buchhalterische Anforderungen zu erf&uuml;llen.</p>
<p>&nbsp;</p>
<h2>Ihre Rechte als Betroffener</h2>
<h3>&nbsp;Auskunftsrecht</h3>
<p>Sie haben das Recht, von uns jederzeit auf Antrag eine Auskunft &uuml;ber die von uns verarbeiteten, Sie betreffenden personenbezogenen Daten im Umfang des Art. 15 DSGVO zu erhalten. Hierzu k&ouml;nnen Sie einen Antrag postalisch oder per E-Mail an die unten angegebene Adresse stellen.</p>
<h3>&nbsp;Recht zur Berichtigung unrichtiger Daten</h3>
<p>Sie haben das Recht, von uns die unverz&uuml;gliche Berichtigung der Sie betreffenden personenbezogenen Daten zu verlangen, sofern diese unrichtig sein sollten. Wenden Sie sich hierf&uuml;r bitte an die unten angegebenen Kontaktadressen.</p>
<p>Recht auf L&ouml;schung</p>
<p>Sie haben das Recht, unter den in Art. 17 DSGVO beschriebenen Voraussetzungen von uns die L&ouml;schung der Sie betreffenden personenbezogenen Daten zu verlangen. Diese Voraussetzungen sehen insbesondere ein L&ouml;schungsrecht vor, wenn die personenbezogenen Daten f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind, sowie in F&auml;llen der unrechtm&auml;&szlig;igen Verarbeitung, des Vorliegens eines Widerspruchs oder des Bestehens einer L&ouml;schungspflicht nach Unionsrecht oder dem Recht des Mitgliedstaates, dem wir unterliegen. Zum Zeitraum der Datenspeicherung siehe im &Uuml;brigen Ziffer 5 dieser Datenschutzerkl&auml;rung. Um Ihr Recht auf L&ouml;schung geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.</p>
<h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
<p>&nbsp;Sie haben das Recht, von uns die Einschr&auml;nkung der Verarbeitung nach Ma&szlig;gabe des Art. 18 DSGVO zu verlangen. Dieses Recht besteht insbesondere, wenn die Richtigkeit der personenbezogenen Daten zwischen dem Nutzer und uns umstritten ist, f&uuml;r die Dauer, welche die &Uuml;berpr&uuml;fung der Richtigkeit erfordert, sowie im Fall, dass der Nutzer bei einem bestehenden Recht auf L&ouml;schung anstelle der L&ouml;schung eine eingeschr&auml;nkte Verarbeitung verlangt; ferner f&uuml;r den Fall, dass die Daten f&uuml;r die von uns verfolgten 6 Zwecke nicht l&auml;nger erforderlich sind, der Nutzer sie jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigt sowie, wenn die erfolgreiche Aus&uuml;bung eines Widerspruchs zwischen uns und dem Nutzer noch umstritten ist. Um Ihr Recht auf Einschr&auml;nkung der Verarbeitung geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.</p>
<h3>Recht auf Daten&uuml;bertragbarkeit</h3>
<p>Sie haben das Recht, von uns die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen, maschinenlesbaren Format nach Ma&szlig;gabe des Art. 20 DSGVO zu erhalten. Um Ihr Recht auf Daten&uuml;bertragbarkeit geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.</p>
<h2>Widerspruchsrecht</h2>
<p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die u.a. aufgrund von Art. 6 Abs. 1 lit. e) oder f) DSGVO erfolgt, Widerspruch nach Art. 21 DSGVO einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder wenn die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</p>
<p>&nbsp;Beschwerderecht</p>
<p>Sie haben ferner das Recht, sich bei Beschwerden an die zust&auml;ndige Aufsichtsbeh&ouml;rde zu wenden. Die zust&auml;ndige Aufsichtsbeh&ouml;rde ist:</p>
<p>DER&nbsp;HAMBURGISCHE&nbsp;BEAUFTRAGTE&nbsp;F&Uuml;R&nbsp;DATENSCHUTZ&nbsp;UND&nbsp;INFORMATIONSFREIHEIT</p>
<p>Ludwig-Erhard-Str 22, 7. OG<br /> 20459 Hamburg</p>
<h2>&nbsp;Kontakt</h2>
<p>Sollten Sie Fragen oder Anmerkungen zu unserem Umgang mit Ihren personenbezogenen Daten haben oder m&ouml;chten Sie Ihre Rechte als betroffene Person aus&uuml;ben, wenden Sie sich bitte</p>
<p>Halalo GmbH &amp; co. KG</p>
<p>Langbargheide 4</p>
<p>22547 Hamburg</p>
<p>service@halalo.de</p>

`;

export default Privacy;

import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div className='d-flex justify-content-between align-items-center links fixed'>
                <Link to='/Impressum'>Impressum</Link>
                <Link to='/app-datenschutz'>Datenschutz</Link>
                <Link to='/AGB'>AGB</Link>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
                <span>&copy; {new Date().getFullYear()} Halalo</span>
            </div>
        </footer>
    )
}

export default Footer

import { useMemo } from "react";
// import { useTranslation } from "react-i18next";

const Order = () => {
  const ua = navigator.userAgent || navigator.vendor
  const isIOS = useMemo(() => /iPad|iPhone|iPod/.test(ua), [ua]);
  const isAndroid = useMemo(() => /Android/.test(ua), [ua]);

  // const { t } = useTranslation();
  // const text = useCallback((key) => t(`content.Home.${key}`), [t]);

  return (
    <div className="d-flex flex-row">
      {!isIOS && (
        <a href="https://play.google.com/store/apps/details?id=com.halalo.app" target="_blank" rel="noopener noreferrer">
          <img src={require("../assets/images/googleDownload.png")}
            alt="google-play-download"
            style={{ width: "10rem", height: "3.6rem" }}
          />
        </a>
      )}
      {!isAndroid && (
        <a href="https://apps.apple.com/us/app/halalo-lieferdienst/id1597930324" target="_blank" rel="noopener noreferrer">
          <img src={require("../assets/images/appleDownload.png")}
            style={{ width: "10rem", height: "3.5rem", marginLeft: "1rem" }}
            alt="google-play-download" />
        </a>
      )}
    </div>
  );
};

export default Order;



// {/* <div className="d-flex flex-column h-50 justify-content-evenly">
//     <h1 className="color-primary font-weight-bold">{text("orderNow")}</h1>
//     <div className="color-primary download-desc">
//       {text("orderDescription")}
//     </div>
//   </div> */}
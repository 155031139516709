import React from "react";

type ICard = {
  title: string;
  description: string;
  image: string;
};

const AdsCard: React.FC<ICard> = ({ image, title, description }) => {
  return (
    <div className="ad-card">
      <img
        src={image}
        alt="card-img"
        className="ads-img"
        loading="lazy"
      />
      <div className="card-body w-80 ad-body">
        <h4
          className="card-title w-75 card-description title"
          style={{ maxWidth: "11em" }}
        >
          {title}
        </h4>
        <p className="card-text w-75 card-description secondary">
          {description}
        </p>
      </div>
    </div>
  );
};

export default AdsCard;

import image2 from "../assets/images/image2.jpg";
import image3 from "../assets/images/image3.jpg";
import image4 from "../assets/images/image4.jpg";
import image5 from "../assets/images/image5.jpg";

const ads = [
  {
    id: 1,
    image: image2,
    title: "Bestelle ganz einfach über die App",
    description:
      "Wähle ein Geschäft in Deiner Nähe - Bestelle exklusive, frische Lebensmittel von spitzen Marken.",
  },
  {
    id: 2,
    image: image3,
    title: "Wir liefern deine Produkte",
    description:
      "Immer Zuverlässig - Unsere Rider arbeiten zu fairen Bedingungen.",
  },
  {
    id: 3,
    image: image5,
    title: "Wir übergeben dir deine Produkte",
    description:
      "Pünktlich - Egal, was du benötigt: Wir liefern dir deine Produkte nachhaus’",
  },
  {
    id: 4,
    image: image4,
    title: "Wir liefern in ca.45 Minuten",
    description:
      "Wähle ein Geschäft in Deiner Nähe - Bestelle exklusive, frische Lebensmittel von spitzen Marken.",
  },
];

export default ads;

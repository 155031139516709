import { Route, Routes } from "react-router";

import "bootstrap/dist/css/bootstrap.min.css";

import { Home, Screen } from "./screens";
import { Footer } from "./layout";

import "./App.css";

function App() {
  return (
    <div className="d-flex flex-column  align-items-center   w-100">
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route
          path={"/Impressum"}
          element={<Screen title="Impressum" apiQuery="aboutUs" />}
        />
        <Route
          path={"/app-datenschutz"}
          element={<Screen title="Datenschutz" apiQuery="privacy" />}
        />
        <Route
          path={"/AGB"}
          element={<Screen title="AGB" apiQuery="termsAndConditions" />}
        />
        <Route path={"/*"} element={<h1> 404 Not Found </h1>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

const AGB = `
<h3>Allgemeine Geschäftsbedingungen</h3>
<p style="text-align:left;">Vertragsbedingungen im Rahmen von Kaufverträgen, die über die Plattform „Halalo“ und/oder über die Homepage „https://halalo.de“</p>
<p><br>zwischen&nbsp;</p>
<p style="text-align:left;"></p>
<p style="text-align:left;">der Halalo GmbH &amp; Co. KG</p>
<p style="text-align:left;">Langbargheide 4</p>
<p style="text-align:left;">22547 Hamburg</p>
<p style="text-align:left;"><br>Eintragen: Handelsregister des Amtsgerichts USt-Identifikations-Nr.: DE (Telefonnummer), eingetragen im unter HRB/A, vertreten durch den Geschäftsführer</p>
<p style="text-align:left;">– im Folgenden „Anbieter“ genannt –</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">und&nbsp;</p>
<p style="text-align:left;"><br><br>den in § 2 des Vertrags bezeichneten Kunden</p>
<p style="text-align:left;">– im Folgenden „Kunde“ genannt –</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">geschlossen werden.</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 1 Geltungsbereich, Begriffsbestimmungen&nbsp;</p>
<ol>
<li style="text-align:left;">Für die Geschäftsbeziehung zwischen dem Webshopanbieter (nachfolgend „Anbieter“) und dem Kunden (nachfolgend „Kunde“) gelten ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen in ihrer zum Zeitpunkt der Bestellung gültigen Fassung. Abweichende allgemeine Geschäftsbedingungen des Bestellers werden nicht anerkannt, es sei denn, der Anbieter stimmt ihrer Geltung ausdrücklich schriftlich zu.</li>
<li style="text-align:left;">Der Kunde ist Verbraucher, soweit der Zweck der georderten Lieferungen und Leistungen nicht überwiegend seiner gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden kann. Dagegen ist Unternehmer jede natürliche oder juristische Person oder rechtsfähige Personengesellschaft, die beim Abschluss des Vertrags in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 2 Vertragsschluss&nbsp;</p>
<ol>
<li style="text-align:left;">Um über die Halalo-App oder über die o.g. Homepage einkaufen zu können, muss sich der Kunde zunächst per E-Mail oder per Telefonnummer bei der Halalo GmbH &amp; Co. KG verifizieren lassen und erhält daraufhin eine Verifizierungsbestätigung per E-Mail bzw. per SMS.</li>
<li style="text-align:left;">Sodann kann der Kunde aus dem verfügbaren Sortiment Produkte, insbes. Lebensmittel des alltäglichen Bedarfs auswählen und diese über den Button „in den Warenkorb legen“ in einem so genannten Warenkorb sammeln. Über den Button „zahlungspflichtig bestellen“ gibt er einen verbindlichen Antrag zum Kauf der im Warenkorb befindlichen Waren ab. Vor Abschicken der Bestellung kann der Kunde die Daten jederzeit ändern und einsehen. Der Antrag kann jedoch nur abgegeben und übermittelt werden, wenn der Kunde durch Klicken auf den Button „AGB akzeptieren“ diese Vertragsbedingungen akzeptiert und dadurch in seinen Antrag aufgenommen hat.</li>
<li style="text-align:left;">Der Anbieter schickt daraufhin dem Kunden eine automatische Empfangsbestätigung per E-Mail zu, in welcher die Bestellung des Kunden nochmals aufgeführt wird und die der Kunde über die Funktion „Drucken“ ausdrucken kann. Die automatische Empfangsbestätigung dokumentiert lediglich, dass die Bestellung des Kunden beim Anbieter eingegangen ist und stellt keine Annahme des Antrags dar. Der Vertrag kommt erst durch die Abgabe der Annahmeerklärung durch den Anbieter zustande, die mit einer gesonderten E-Mail (Auftragsbestätigung) versandt wird. In dieser E-Mail oder in einer separaten E-Mail, jedoch spätestens bei Lieferung der Ware, wird der Vertragstext (bestehend aus Bestellung, AGB und Auftragsbestätigung) dem Kunden von uns auf einem dauerhaften Datenträger (E-Mail oder Papierausdruck) zugesandt (Vertragsbestätigung). Der Vertragstext wird unter Wahrung des Datenschutzes gespeichert.</li>
<li style="text-align:left;">Der Vertragsschluss erfolgt in deutscher Sprache.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 3 Lieferung, Warenverfügbarkeit&nbsp;</p>
<ol>
<li style="text-align:left;">Die Halalo GmbH &amp; Co. KG ist stets bemüht, die erfolgten Bestellungen innerhalb von 40 Minuten ab dem Zeitpunkt der Auftragsbestätigung auszuliefern. Von uns angegebene Lieferzeiten berechnen sich vom Zeitpunkt unserer Auftragsbestätigung, vorherige Zahlung des Kaufpreises vorausgesetzt (außer beim Rechnungskauf). Sofern für die jeweilige Ware in unserem Online-Shop keine abweichende Lieferzeit angegeben ist, beträgt sie 3 Werktage.</li>
<li style="text-align:left;">Sind zum Zeitpunkt der Bestellung des Kunden keine Exemplare des von ihm ausgewählten Produkts verfügbar, so teilt der Anbieter dem Kunden dies in der Auftragsbestätigung unverzüglich mit. Ist das Produkt dauerhaft nicht lieferbar, sieht der Anbieter von einer Annahmeerklärung ab. Ein Vertrag kommt in diesem Fall nicht zustande.</li>
<li style="text-align:left;">Ist das vom Kunden in der Bestellung bezeichnete Produkt nur vorübergehend nicht verfügbar, teilt der Anbieter dem Kunden dies ebenfalls unverzüglich in der Auftragsbestätigung mit.</li>
<li style="text-align:left;">Es bestehen die folgenden Lieferbeschränkungen: Der Anbieter liefert nur an Kunden, die ihren gewöhnlichen Aufenthalt (Rechnungsadresse) in einem der nachfolgenden Länder haben und im selben Land eine Lieferadresse angeben können: Deutschland.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 4 Eigentumsvorbehalt&nbsp;</p>
<p style="text-align:left;">Bis zur vollständigen Bezahlung verbleiben die gelieferten Waren im Eigentum des Anbieters.</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 5 Preise und Versandkosten&nbsp;</p>
<ol>
<li style="text-align:left;">Alle Preise, die auf der Website des Anbieters bzw. in er Halalo-App angegeben sind, verstehen sich einschließlich der jeweils gültigen gesetzlichen Umsatzsteuer.</li>
<li style="text-align:left;">Die entsprechenden Versandkosten werden dem Kunden im Bestellformular angegeben und sind vom Kunden zu tragen, soweit der Kunde nicht von seinem Widerrufsrecht Gebrauch macht. Ab einem Warenbestellwert von EUR 80,00 liefert der Anbieter an den Kunden versandkostenfrei.</li>
<li style="text-align:left;">Der Versand der Ware erfolgt per Lieferservice. Das Versandrisiko trägt der Anbieter, wenn der Kunde Verbraucher ist.&nbsp;</li>
<li style="text-align:left;">Der Kunde hat im Falle eines Widerrufs die unmittelbaren Kosten der Rücksendung zu tragen, Art. 246a § 1 Abs. 2 S. 1 Nr. 2 EGBGB.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 6 Zahlungsmodalitäten&nbsp;</p>
<ol>
<li style="text-align:left;">Der Kunde kann die Zahlung per PayPal, Kreditkarte und auf Rechnung (Barzahlung) vornehmen. Die Zahlung auf Rechnung ist für Neukunden bei der ersten Bestellung nicht möglich.</li>
<li style="text-align:left;">Die Zahlung des Kaufpreises ist unmittelbar mit Vertragsschluss fällig. Ist die Fälligkeit der Zahlung nach dem Kalender bestimmt, so kommt der Kunde bereits durch Versäumung des Termins in Verzug. In diesem Fall hat er dem Anbieter für das Jahr Verzugszinsen i.H.v. 5 Prozentpunkten über dem Basiszinssatz zu zahlen.</li>
<li style="text-align:left;">Die Verpflichtung des Kunden zur Zahlung von Verzugszinsen schließt die Geltendmachung weiterer Verzugsschäden durch den Anbieter nicht aus.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 7 Sachmängelgewährleistung, Garantie&nbsp;</p>
<ol>
<li style="text-align:left;">Der Anbieter haftet für Sachmängel nach den hierfür geltenden gesetzlichen Vorschriften, insbesondere §§ 434 ff. BGB. Gegenüber Unternehmern beträgt die Gewährleistungsfrist auf vom Anbieter gelieferte Sachen zwölf Monate.&nbsp;</li>
<li style="text-align:left;">Eine zusätzliche Garantie besteht bei den vom Anbieter gelieferten Waren nur, wenn diese ausdrücklich in der Auftragsbestätigung zu dem jeweiligen Artikel abgegeben wurde.&nbsp;</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 8 Haftung&nbsp;</p>
<ol>
<li style="text-align:left;">Ansprüche des Kunden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des Kunden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Anbieters, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.</li>
<li style="text-align:left;">Bei der Verletzung wesentlicher Vertragspflichten haftet der Anbieter nur auf den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um Schadensersatzansprüche des Kunden aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.</li>
<li style="text-align:left;">Die Einschränkungen der Abs. 1 und 2 gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen des Anbieters, wenn Ansprüche direkt gegen diese geltend gemacht werden.</li>
<li style="text-align:left;">Die sich aus Abs. 1 und 2 ergebenden Haftungsbeschränkungen gelten nicht, soweit der Anbieter den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen hat. Das gleiche gilt, soweit der Anbieter und der Kunde eine Vereinbarung über die Beschaffenheit der Sache getroffen haben. Die Vorschriften des Produkthaftungsgesetzes bleiben unberührt.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 9 Widerrufsbelehrung</p>
<ol>
<li style="text-align:left;">Verbraucher haben bei Abschluss eines Fernabsatzgeschäfts grundsätzlich ein gesetzliches Widerrufsrecht, über das der Anbieter nach Maßgabe des gesetzlichen Musters nachfolgend informiert. Die Ausnahmen vom Widerrufsrecht sind in Absatz 2 geregelt. In Absatz 3 findet sich ein Muster-Widerrufsformular.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;Widerrufsbelehrung</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;Widerrufsrecht&nbsp;</p>
<p style="text-align:left;">&nbsp;Sie haben das Recht, binnen vierzehn Tagen ohne Angaben von    Gründen diesen Vertrag zu widerrufen.&nbsp;</p>
<p style="text-align:left;">&nbsp;Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder   ein von Ihnen benannter Dritter, der nicht Beförderer ist, die Ware(n) in   Besitz genommen haben bzw. hat.</p>
<p style="text-align:left;">&nbsp;Um Ihr Widerrufsrecht auszuüben, müssen Sie die</p>
<p style="text-align:left;">&nbsp;&nbsp;</p>
<p style="text-align:left;">&nbsp;&nbsp;Halalo GmbH &amp; Co. KG</p>
<p style="text-align:left;">&nbsp;&nbsp;Langbargheide 4</p>
<p style="text-align:left;">&nbsp;&nbsp;22547 Hamburg</p>
<p style="text-align:left;">&nbsp;&nbsp;Telefon:.………………………………….</p>
<p style="text-align:left;">&nbsp;&nbsp;E-Mail:……………………………………</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter    Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,   informieren. Sie können dafür das beigefügte Muster-Widerrufsformular   verwenden, das jedoch nicht vorgeschrieben ist.&nbsp;</p>
<p style="text-align:left;">&nbsp;Sie können das Muster-Widerrufsformular oder eine andere eindeutige   Erklärung auch auf unserer Webseite „https://halalo.de“ elektronisch    ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit     Gebrauch, so werden wir Ihnen unverzüglich (per E-Mail) eine     Bestätigung über den Eingang eines solchen Widerrufes übermitteln.</p>
<p style="text-align:left;">&nbsp;Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung    über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist    absenden.</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;Folgen des Widerrufs&nbsp;</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,   die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit    Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie   eine andere Art der Lieferung als die von uns angebotene, günstige    Standardlieferung gewählt haben), unverzüglich und spätestens binnen   vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über  Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese    Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der   ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen    wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden    Ihnen wegen dieser Rückzahlung Entgelte berechnet.&nbsp;</p>
<p style="text-align:left;">&nbsp;Wir können die Rückzahlung verweigern, bis wir die Ware wieder    zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass   Sie die Waren zurückgesandt haben, je nachdem, welches der frühere   Zeitpunkt ist.&nbsp;</p>
<p style="text-align:left;">&nbsp;Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen   vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses   Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die  Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn   Tagen absenden.&nbsp;</p>
<p style="text-align:left;">&nbsp;Sie tragen die unmittelbaren Kosten der Rücksendung der     Waren.</p>
<p style="text-align:left;">&nbsp;Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen,   wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,    Eigenschaften und Funktionsweise der Waren nicht notwendigen    Umgang mit ihnen zurückzuführen ist.</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">Ende der Belehrung zum Widerrufsrecht</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<ol>
<li style="text-align:left;">Das Widerrufsrecht besteht nicht bei&nbsp;</li>
</ol>
<p style="text-align:left;"><br></p>
<ul>
<li style="text-align:left;">Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Kunden maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Kunden zugeschnitten sind,</li>
<li style="text-align:left;">schnell verderblichen Waren oder Waren, deren Verfallsdatum schnell überschritten würde,</li>
<li style="text-align:left;">versiegelten Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde,</li>
<li style="text-align:left;">Waren, wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden.</li>
</ul>
<ol>
<li style="text-align:left;">Über das Muster-Widerrufsformular informiert der Anbieter nach der gesetzlichen Regelung wie folgt:</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;Muster-Widerrufsformular</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus   und senden Sie es zurück.)&nbsp;</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;An: Halalo GmbH &amp; Co. KG, Langbargheide 4, 22547 Hamburg, E-Mail:     ……………………………………… / Telefon: ………………………………………</p>
<p style="text-align:left;"><br> Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über   den Kauf der folgenden Waren (*)</p>
<p style="text-align:left;">&nbsp;Bestellt am …………………… / erhalten am ………………………<br> Name des/der Verbraucher(s)…………………………………………<br> Anschrift des/der Verbraucher(s) ……………………………………………………    ……………………………………………………………………………………………    ……………………………………………………………………………………………</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">&nbsp;……………………………………………………<br> Datum, Unterschrift des/der Verbraucher(s) — (nur bei Mitteilung auf Papier)</p>
<p style="text-align:left;">&nbsp;(*) Unzutreffendes streichen</p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">§ 10 Schlussbestimmungen28&nbsp;</p>
<ol>
<li style="text-align:left;">Auf Verträge zwischen dem Anbieter und den Kunden findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbes. des Staates, in dem der Kunde als Verbraucher seinen gewöhnlichen Aufenthalt hat, bleiben unberührt. Die Halalo GmbH &amp; Co. KG ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</li>
<li style="text-align:left;">Sofern es sich beim Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und dem Anbieter der Sitz des Anbieters.</li>
<li style="text-align:left;">Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise unwirksam oder undurchführbar sein oder werden, so berührt dies den Vertrag im Übrigen nicht. An Stelle der unwirksamen oder undurchführbaren Bestimmung gilt eine solche als vereinbart, die dem von den Parteien gewollten Zweck der unwirksamen oder undurchführbaren Regelung am nächsten kommt. Soweit dies für eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam.</li>
</ol>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;"><br></p>
<p style="text-align:left;">Hamburg, September 2021&nbsp;</p>
`
export default AGB
import AdsCard from "./common/ads-card";
import ads from "../utills/_ads";

const Ads = () => (
  <div className="d-flex align-items-center justify-content-center ad-container ">
    {ads.map((ad) => (
      <AdsCard key={ad.id} {...ad} />
    ))}
  </div>
);

export default Ads;

import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BackNav = () => {
  const { t } = useTranslation();
  const text = useCallback((key) => t(`content.${key}`), [t]);
  return (
    <div className="back-nav">
      <Link to={"/"} className="link black">
        {text("back")}
      </Link>
    </div>
  );
};

export default BackNav;

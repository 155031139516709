import image1 from "../assets/images/image1.jpg";
import image7 from "../assets/images/image7.png";
const Banner = () => {
  const ads = [
    "Halal zertifizierte Produkte",
    "Bequem online bestellen",
    "Gesamtes Marktsortiment",
  ];

  return (
    <div className="banner w-100 d-flex justify-content-between">
      <div className="banner-first">
        <img className="banner-first-img" src={image1} alt="banner" />
      </div>
      <div className="banner-first banner-second">
        {ads.map((ad, index) => (
          <div
            key={index}
            className="d-flex w-75 justify-content-start align-items-center"
          >
            <div className="w-25">
              <img
                className="true-svg"
                src="https://icon-library.com/images/trust-icon-png/trust-icon-png-9.jpg"
                alt="trust"
                loading="lazy"
              />
            </div>
            <div className="d-flex align-items-center">
              <p className="color-secondary">{ad}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="banner-first d-flex justify-content-center align-items-center">
        <img className="w-50" src={image7} alt="banner" loading="lazy" />
      </div>
    </div>
  );
};
export default Banner;

// import { useState } from "react";

import { Header } from "../layout";

// import Cookies from "../components/Cookies";
import Banner from "../components/Banner";
// import Order from "../components/Order";
import Ads from "../components/Ads";

const Home = () => {
  // const [accept, setAccept] = useState(false);

  return (
    <>
      <Header />
      <div className="home">
        {/* {!accept && <Cookies setAccept={setAccept} />} */}
        {/* <Order /> */}
        <Ads />
      </div>
      <Banner />
    </>
  );
};

export default Home;
